import React, { useEffect, useReducer } from 'react';
import { CountryCode, RecommendedBox, SelectedBox } from '@dayetopia/types';
import { useLocation } from '@reach/router';
import * as Sentry from '@sentry/react';
import axios from 'axios';
import { initializeApp } from 'firebase/app';
import { Auth, browserLocalPersistence, indexedDBLocalPersistence, initializeAuth, onAuthStateChanged, User } from 'firebase/auth';
import { navigate } from 'gatsby';
import { useAccountStore } from '@components/dashboard/context/UserDashboardContext';
import { getFromLocalStorage, setOnLocalStorage } from '@components/shared/utilLocalStorage';
import {
  CartItem,
  CartItemGiftType,
  CartItems,
  CartItemType,
  CurrencyObj,
  ProductCode,
  ProductConfiguration,
  PromotionObject,
  ShippingFees,
  ShippingObject
} from '@contracts';
import getOmsUrl from '@utils/getOmsUrl';
import { isPromotionActive } from '@utils/helpers';
import { clearSessionStorage, getFromStorage, hydrateInitialState, setOnSessionStorage } from '@utils/sessionStorage';
import { addToCartTrack, identifyUser, removeFromCartTrack } from '@utils/tracking';
import packageJson from '../../package.json';
import useFetch from './useFetch';

const ecomDataTimestampKey = `ecomDataTimestamp-${packageJson.version}`;

export const initialState: AppState = {
  firebaseAuth: undefined,
  firebaseDb: undefined,
  firebaseLoaded: false,
  loggedIn: null,
  userId: '',
  selectedPlan: '28',
  selectedBox: {
    boxSize: 12,
    cbdregular: 0,
    cbdsuper: 6,
    nakedregular: 3,
    nakedsuper: 3
  },
  recommendedBox: undefined,
  cartCount: 0,
  cartItems: [],
  statusMessage: false,
  discountExpiryTime: undefined,
  discountAmount: undefined,
  price: 0,
  tax: undefined,
  recurringPrice: 0,
  currency: {
    symbol: '£',
    letterCode: 'GBP'
  },
  country: CountryCode.GB,
  mfaVerifyId: undefined,
  freeShippingRequirement: 50,
  shippingCost: { originalShippingPrice: 0, shippingPrice: 0 },
  shippingFees: {
    blm_pin: { originalShippingPrice: 1, shippingPrice: 1 },
    cbd_balm: { originalShippingPrice: 2, shippingPrice: 2 },
    gift_card: { originalShippingPrice: 0, shippingPrice: 0 },
    holiday_sampler: { originalShippingPrice: 0, shippingPrice: 0 },
    mask_blue: { originalShippingPrice: 2, shippingPrice: 2 },
    mask_dark: { originalShippingPrice: 2, shippingPrice: 2 },
    mask_light: { originalShippingPrice: 2, shippingPrice: 2 },
    mask_pink: { originalShippingPrice: 2, shippingPrice: 2 },
    proviotics: { originalShippingPrice: 2, shippingPrice: 2 },
    sampler: { originalShippingPrice: 0, shippingPrice: 0 },
    tampon_box: { originalShippingPrice: 2.8, shippingPrice: 0 },
    tampon_pouch: { originalShippingPrice: 0, shippingPrice: 0 },
    tin_case: { originalShippingPrice: 2, shippingPrice: 2 },
    us_waitlist_prepaid_12: { originalShippingPrice: 0, shippingPrice: 0 },
    us_waitlist_prepaid_18: { originalShippingPrice: 0, shippingPrice: 0 },
    vagina_matters_book: { originalShippingPrice: 2, shippingPrice: 0 },
    valentines_box: { originalShippingPrice: 2, shippingPrice: 2 },
    valentines_sampler: { originalShippingPrice: 0, shippingPrice: 0 },
    masks: { originalShippingPrice: 0, shippingPrice: 0 },
    screening_kit: { originalShippingPrice: 0, shippingPrice: 0 },
    rose_candle: { originalShippingPrice: 2.5, shippingPrice: 2.5 }
  }
};

const mockDispatch = (a: ActionTypes) => {
  console.debug(a);
};

const initial: IContext = {
  state: initialState,
  dispatch: mockDispatch
};

function updateCartSession(cartItems: CartItems) {
  setOnLocalStorage('cartItems', cartItems, true);
}

export function getUniqueCartId(state: AppState, productCode: string) {
  const existingItemsForProductCode = state.cartItems.filter((item: CartItem) => item.productCode === productCode);
  const maxNumber = existingItemsForProductCode.reduce((max, item) => {
    const idNumber = parseInt(item.id.split('-')[1], 10);
    return Math.max(max, idNumber);
  }, 0);
  const newNumber = maxNumber + 1;
  return `${productCode}-${newNumber}`;
}

function getDependantCartItems(cartItem) {
  return cartItem?.dependantCartItems ?? [];
}

function getDependantFreeGifts(adjustedCartItems, cartItem, country, currency) {
  return handleFreeGifts(adjustedCartItems, cartItem, country, currency);
}

function combineDependantItems(dependantCartItems, dependantFreeGifts) {
  return [...dependantCartItems, ...dependantFreeGifts];
}

function updateDependantCartItems(cartItem, combinedDependantItems) {
  cartItem.dependantCartItems = combinedDependantItems;
}

function processDependantCartItems(state, adjustedCartItems, cartItem) {
  cartItem.dependantCartItems?.forEach((ci) => {
    adjustedCartItems = handleCartItemAdd(state, adjustedCartItems, ci, false);
  });

  return adjustedCartItems;
}

// TODO this function currently handles not only cart item addition, but also increasing cart item quantity
// These two actions should be separated
function handleCartItemAdd(state: AppState, cartItems: CartItem[], cartItem: CartItem, trackItems: Boolean = true) {
  const email = state?.firebaseAuth?.currentUser?.email ?? '';
  let adjustedCartItems = [...cartItems];
  const existingCartItem = adjustedCartItems.find((item: CartItem) => item.id === cartItem.id && item.type === cartItem.type);
  if (existingCartItem?.type === CartItemType.Subscription) {
    adjustedCartItems = handleCartItemRemove(state, cartItems, cartItem);
  }
  if (!existingCartItem || existingCartItem?.type === CartItemType.Subscription) {
    // adding new cart item
    adjustedCartItems.push(cartItem);
  } else if (existingCartItem.editable && !cartItem.editable) {
    adjustedCartItems = handleCartItemRemove(state, cartItems, existingCartItem);
    adjustedCartItems = handleCartItemAdd(state, adjustedCartItems, cartItem);
  } else if (cartItem.price > 0) {
    // increasing cart item quantity
    // TODO do not directly increase the quantity of the existing element, rather create a new element
    existingCartItem.quantity += cartItem.quantity;
  }

  const dependantCartItems = getDependantCartItems(cartItem);
  const dependantFreeGifts = getDependantFreeGifts(adjustedCartItems, cartItem, state.country, state.currency);
  const combinedDependantItems = combineDependantItems(dependantCartItems, dependantFreeGifts);

  if (trackItems) addToCartTrack(cartItem, email);

  updateDependantCartItems(cartItem, combinedDependantItems);
  adjustedCartItems = processDependantCartItems(state, adjustedCartItems, cartItem);

  return adjustedCartItems;
}

export function createFreeGiftWithProductCode(productCode: ProductCode, country: CountryCode, currency: CurrencyObj, type: CartItemGiftType) {
  let originalPrice = 0;
  const pricesData = getFromLocalStorage('productPrices', true);
  if (pricesData && Array.isArray(pricesData)) {
    const product = pricesData.find((itm) => itm.id === productCode);
    if (product && product.price) {
      const vatPercent = product?.vat[country] ?? 0;
      const price = product.price.find((p) => p.currency === currency.letterCode);
      if (price) {
        const vatAmount = price.amount * (vatPercent / 100);
        const finalPrice = price.amount + vatAmount;
        originalPrice = finalPrice;
      }
    }
  }
  return new CartItem(`${productCode}_free`, productCode, 0, 1, CartItemType.OneOffPurchase, false, type, originalPrice);
}

const handleFreeGifts = (cartItems: CartItem[], cartItem: CartItem, country: CountryCode, currency: CurrencyObj) => {
  const freeGifts: CartItem[] = [];
  const promotions: PromotionObject[] = getFromLocalStorage('promotions', true) ?? [];

  if (!Array.isArray(promotions)) return [];

  promotions.forEach((promo) => {
    const isPromoActive = isPromotionActive(promo, country, cartItem.productCode);
    const cartItemIsFreeGift = cartItem.id.includes('_free');
    const shouldAddFreeGift = isPromoActive && promo.freeGift && !promo.couponCode && !cartItemIsFreeGift;

    if (!shouldAddFreeGift) {
      return;
    }

    const isFreeGiftNotInCart = (promoId: string) => !cartItems.find((item) => item.id === `${promoId}_free`);
    const addFreeGift = () => {
      if (isFreeGiftNotInCart(promo.freeGift)) {
        const freeGift = createFreeGiftWithProductCode(promo.freeGift as ProductCode, country, currency, CartItemGiftType.PromotionCode);
        freeGifts.push(freeGift);
      }
    };

    if (cartItem.type === CartItemType.Subscription) {
      const planCode = cartItem.selectedPlan?.planCode ?? '';
      const isMultiMonthPlan = promo.planCodes?.some((code) => planCode.includes(code));
      if (isMultiMonthPlan) {
        addFreeGift();
      }
    } else {
      addFreeGift();
    }
  });

  return freeGifts;
};

// TODO this function currently handles not only cart item removal, but also decreasing cart item quantity
// These two actions should be separated
function handleCartItemRemove(state: AppState, cartItems: CartItem[], itemToRemove: CartItem) {
  const email = state?.firebaseAuth?.currentUser?.email ?? '';

  const itemToBeRemoved = cartItems.find((item: CartItem) => item.id === itemToRemove.id && item.type === itemToRemove.type);
  let adjustedItems = [...cartItems];
  if (itemToBeRemoved) {
    if (itemToBeRemoved.editable || itemToBeRemoved.quantity - 1 <= 0) {
      // removing cart item
      adjustedItems = cartItems.filter((item: CartItem) => item.id !== itemToBeRemoved.id || item.type !== itemToBeRemoved.type);
    } else if (itemToBeRemoved.quantity - 1 > 0) {
      // decreasing cart item quantity but keeping it in the cart
      // TODO do not directly decrease the quantity of the existing element, rather create a new element
      itemToBeRemoved.quantity -= 1;
    }
    removeFromCartTrack(itemToBeRemoved, email);
  }
  if (itemToBeRemoved && itemToBeRemoved.dependantCartItems) {
    itemToBeRemoved.dependantCartItems.forEach((di) => {
      adjustedItems = handleCartItemRemove(state, adjustedItems, di);
    });
  }
  return adjustedItems;
}

function handleCartItemDependentItemsRemove(cartItems: CartItem[], itemToRemove: CartItem) {
  const itemIndex = cartItems.findIndex((item) => item.id === itemToRemove.id && item.type === itemToRemove.type);
  if (itemIndex !== -1 && cartItems[itemIndex].dependantCartItems) {
    const newCartItems = [...cartItems];
    newCartItems[itemIndex] = { ...newCartItems[itemIndex], dependantCartItems: undefined };
    return newCartItems;
  }
  return [...cartItems];
}

function getShippingCostValue(state: AppState) {
  let shippingCostValue: number = 0;
  let originalShippingCostValue: number = 0;
  const freeShippingRequirementMet = state.price > state.freeShippingRequirement;
  const maxOneOffPurchaseShippingFee = state.cartItems
    .filter((item) => item.type === CartItemType.OneOffPurchase && !item.id.includes('_free'))
    .reduce(
      (previousValue, cartItem: CartItem) => {
        const codeForShipping = cartItem.productCode;

        return {
          ...previousValue,
          maxOriginalShippingFee: Math.max(previousValue.maxOriginalShippingFee, state.shippingFees[codeForShipping]?.originalShippingPrice ?? 0),
          maxShippingFee: Math.max(previousValue.maxShippingFee, state.shippingFees[codeForShipping]?.shippingPrice ?? 0)
        };
      },
      { maxOriginalShippingFee: 0, maxShippingFee: 0 }
    );

  shippingCostValue += maxOneOffPurchaseShippingFee.maxShippingFee;
  originalShippingCostValue += maxOneOffPurchaseShippingFee.maxOriginalShippingFee;

  const cartItemsWithoutOneOffs = state.cartItems.filter((item) => item.type !== CartItemType.OneOffPurchase);

  cartItemsWithoutOneOffs.forEach((item) => {
    const codeForShipping = item.productCode;

    shippingCostValue += state.shippingFees[codeForShipping]?.shippingPrice ?? 0;
    originalShippingCostValue += state.shippingFees[codeForShipping]?.originalShippingPrice ?? 0;
    item.shippingFee = freeShippingRequirementMet ? 0 : state.shippingFees[codeForShipping]?.shippingPrice ?? 0;
  });

  if (freeShippingRequirementMet) {
    return { originalShippingPrice: originalShippingCostValue, shippingPrice: 0 };
  }

  const freeItemCheck = state.cartItems.length === 1 && state.cartItems.find((item) => item.price === 0);
  const oneOffTamponBoxCheck = state.cartItems.find(
    (item) => item.productCode === ProductCode.TamponBox && item.type === CartItemType.OneOffPurchase
  );

  if (freeItemCheck || oneOffTamponBoxCheck) {
    return { originalShippingPrice: originalShippingCostValue, shippingPrice: originalShippingCostValue };
  }

  return { originalShippingPrice: originalShippingCostValue, shippingPrice: shippingCostValue };
}

const Context = React.createContext(initial);

function reducer(state: AppState, action: ActionTypes): AppState {
  switch (action.type) {
    case 'setFirebaseAuth':
      return {
        ...state,
        firebaseAuth: action.data
      };
    case 'setFirebaseDb':
      return {
        ...state,
        firebaseDb: action.data
      };
    case 'setFirebaseLoaded':
      return {
        ...state,
        firebaseLoaded: action.data
      };
    case 'setLoggedIn':
      return {
        ...state,
        loggedIn: action.data
      };
    case 'setUserId':
      return {
        ...state,
        userId: action.data
      };
    case 'setSelectedPlan':
      setOnSessionStorage('selectedPlan', action.data);
      return {
        ...state,
        selectedPlan: action.data
      };
    case 'setSelectedBox':
      setOnSessionStorage('selectedBox', action.data);
      return {
        ...state,
        selectedBox: action.data
      };
    case 'cartItemAdd': {
      const adjustedItems = handleCartItemAdd(state, state.cartItems, action.data);
      updateCartSession(adjustedItems);
      return {
        ...state,
        cartItems: adjustedItems
      };
    }
    case 'cartItemRemove': {
      const adjustedItems = handleCartItemRemove(state, state.cartItems, action.data);
      updateCartSession(adjustedItems);
      return {
        ...state,
        cartItems: adjustedItems
      };
    }
    case 'cartItemDependantItemsRemove': {
      const adjustedItems = handleCartItemDependentItemsRemove(state.cartItems, action.data);
      updateCartSession(adjustedItems);
      return {
        ...state,
        cartItems: adjustedItems
      };
    }
    case 'cartItemUpdate': {
      let adjustedItems = handleCartItemRemove(state, state.cartItems, action.data);
      adjustedItems = handleCartItemAdd(state, adjustedItems, action.data);
      updateCartSession(adjustedItems);
      return {
        ...state,
        cartItems: adjustedItems
      };
    }
    case 'cartItemQuantityIncrease': {
      const itemIndex = state.cartItems.findIndex((item: CartItem) => item.id === action.data.id && item.type === action.data.type);
      const updatedCartItems = [...state.cartItems];
      updatedCartItems[itemIndex].quantity += 1;
      updateCartSession(updatedCartItems);

      return {
        ...state,
        cartItems: [...updatedCartItems]
      };
    }
    case 'cartItemQuantityDecrease': {
      const itemIndex = state.cartItems.findIndex((item: CartItem) => item.id === action.data.id && item.type === action.data.type);

      if (itemIndex === -1) {
        console.error('Item not found in cart');
        return state; // If item is not found, return the current state
      }

      const updatedCartItems = [...state.cartItems];
      if (updatedCartItems[itemIndex].quantity === 1) {
        return state;
      }

      updatedCartItems[itemIndex].quantity -= 1;
      updateCartSession(updatedCartItems);

      return {
        ...state,
        cartItems: [...updatedCartItems]
      };
    }
    case 'cartClear':
      updateCartSession([]);
      return {
        ...state,
        cartItems: []
      };
    case 'setPrice':
      return {
        ...state,
        price: action.data
      };
    case 'setTax':
      return {
        ...state,
        tax: action.data
      };
    case 'setRecurringPrice':
      return {
        ...state,
        recurringPrice: action.data
      };
    case 'setFreeShippingRequirement':
      return {
        ...state,
        freeShippingRequirement: action.data
      };
    case 'setRecommendedBox':
      setOnSessionStorage('recommendedBox', action.data);
      return {
        ...state,
        recommendedBox: action.data
      };
    case 'setStatusMessage':
      return {
        ...state,
        statusMessage: action.data
      };
    case 'setDiscountExpiryTime':
      return {
        ...state,
        discountExpiryTime: action.data
      };
    case 'setDiscountAmount':
      return {
        ...state,
        discountAmount: action.data
      };
    case 'setCurrency':
      setOnSessionStorage('currency', action.data);
      return {
        ...state,
        currency: action.data
      };
    case 'setCountry':
      setOnSessionStorage('country', action.data);
      return {
        ...state,
        country: action.data
      };
    case 'setMfaVerifyId':
      setOnSessionStorage('mfaVerifyId', action.data);
      return {
        ...state,
        mfaVerifyId: action.data
      };
    case 'updateShippingCost': {
      const shippingCostValue = getShippingCostValue(state);
      setOnSessionStorage('shippingCost', shippingCostValue);

      return {
        ...state,
        shippingCost: shippingCostValue
      };
    }
    case 'setShippingFees':
      setOnSessionStorage('shippingFees', action.data);
      return {
        ...state,
        shippingFees: action.data
      };
    case 'REHYDRATE-FROM-SESSION-STORAGE':
      return {
        ...action.data
      };
    case 'CLEAR-STATE-AND-STORAGE': {
      const persistOnSession = [
        'selectedBox',
        'recommendedBox',
        'selectedPlan',
        'cartCount',
        'cartItems',
        'price',
        'tax',
        'recurringPrice',
        'freeShippingRequirement',
        'currency',
        'country',
        'mfaVerifyId',
        'shippingCost',
        'shippingFees',
        'firebaseAuth',
        'loggedIn'
      ];
      clearSessionStorage(initialState, persistOnSession);
      return {
        ...initialState,
        firebaseAuth: state.firebaseAuth,
        loggedIn: state.loggedIn,
        firebaseDb: state.firebaseDb,
        firebaseLoaded: state.firebaseLoaded,
        selectedBox: state.selectedBox,
        recommendedBox: state.recommendedBox,
        selectedPlan: state.selectedPlan,
        cartCount: state.cartCount,
        cartItems: state.cartItems,
        price: state.price,
        tax: state.tax,
        recurringPrice: state.recurringPrice,
        freeShippingRequirement: state.freeShippingRequirement,
        currency: state.currency,
        mfaVerifyId: state.mfaVerifyId,
        country: state.country,
        shippingCost: state.shippingCost,
        shippingFees: state.shippingFees
      };
    }
    default:
      throw new Error(JSON.stringify(action, null, 2));
  }
}

interface Props {
  children: React.ReactNode;
}

function Provider({ children }: Props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const resetState = useAccountStore((accountState) => accountState.reset);
  const purchases = useAccountStore((accountState) => accountState.purchases);
  const location = useLocation();
  const userInfo = useAccountStore((accountState) => accountState.userInfo);
  const setUserInfo = useAccountStore((accountState) => accountState.setUserInfo);
  const authFetch = useFetch();

  useEffect(() => {
    initSentry();

    const initialStateWithSessionStorage: AppState = hydrateInitialState(initialState);

    dispatch({
      type: 'REHYDRATE-FROM-SESSION-STORAGE',
      data: initialStateWithSessionStorage
    });

    if (!state.firebaseAuth) setFirebaseAuth();
    getGeolocation();
    checkURLParamsForReferralCode();
    (async () => {
      await getProductPrices();
    })();
  }, []);

  const setUserInfoForTerms = async () => {
    const customerRecord = await authFetch.get('/account/details', state.firebaseAuth?.currentUser);
    const isCustomerEmpty = !customerRecord || Object.keys(customerRecord).length === 0;

    if (!isCustomerEmpty) {
      setUserInfo({ ...userInfo, ...customerRecord });
    }
  };

  useEffect(() => {
    // Initialization check for current user
    const currentUser = state.firebaseAuth?.currentUser;
    if (currentUser !== undefined && state.loggedIn === null) {
      dispatch({ type: 'setLoggedIn', data: !!currentUser });
    }
  }, [state.firebaseAuth]);

  useEffect(() => {
    // Guard clause to prevent running before firebaseAuth is initialized.
    if (!state.firebaseAuth || state.loggedIn === null) return;

    // This sets up a listener for the Firebase Auth state.
    const unsubscribe = onAuthStateChanged(state.firebaseAuth, (user: User | null) => {
      dispatch({ type: 'setFirebaseLoaded', data: true });

      // This function should be synchronous and manage the loggedIn state.
      if (user) {
        handleUserSignedIn(user); // Synchronously updates loggedIn state

        if (state.loggedIn && !userInfo.accountId) {
          setUserInfoForTerms();
        }
      } else {
        clearUserState(); // Synchronously clears loggedIn state
      }

      // Delaying the redirect until the next tick of the event loop to ensure state updates are processed.
      setTimeout(() => {
        const dashboardPagePath = '/dashboard/';
        const loginPagePath = '/dashboard/login/';
        const isDashboardRoute = location.pathname.startsWith(dashboardPagePath);
        const isLoginRoute = location.pathname === loginPagePath;

        // If user is not logged in and is trying to access a dashboard route, redirect to login.
        if (!user && isDashboardRoute && !isLoginRoute) {
          navigate(loginPagePath, { replace: true });
        }
      }, 0);
    });

    // Cleanup the subscription on unmount
    return () => {
      unsubscribe();
    };
  }, [state.loggedIn, state?.firebaseAuth?.currentUser?.emailVerified]);

  useEffect(() => {
    updateOrderPrice();
  }, [state.discountAmount, state.cartItems, state.selectedPlan, state.selectedBox, state.tax]);

  useEffect(() => {
    dispatch({ type: 'updateShippingCost', data: state.country });
  }, [state.cartItems, state.shippingFees, state.price]);

  useEffect(() => {
    checkCartItems();
  }, [state.cartItems, purchases]);

  const initSentry = () => {
    Sentry.init({
      dsn: process.env.SENTRY_DSN_URL,
      environment: process.env.GATSBY_ACTIVE_ENV ?? 'development',
      tracesSampleRate: process.env.GATSBY_ACTIVE_ENV === 'prod' ? 0.1 : 1.0,
      integrations: [new Sentry.BrowserTracing()]
    });
  };

  const checkCartItems = () => {
    const promotions: PromotionObject[] = getFromLocalStorage('promotions', true) ?? [];
    if (!Array.isArray(promotions)) return;

    promotions.forEach((promo) => {
      const currentDate = new Date();
      const startDate = new Date(promo.startDate);
      const endDate = new Date(promo.endDate);
      const isPromoActive = currentDate >= startDate && currentDate <= endDate;

      const freeGift = state.cartItems.find((item) => item.id === `${promo.freeGift}_free` && item.giftType === CartItemGiftType.Promotion);
      if (freeGift && !isPromoActive) {
        dispatch({ type: 'cartItemRemove', data: freeGift });
      }
    });

    const tamponSubscription = purchases.subscriptions.find((subscription) => subscription.planCode?.includes('tampon'));
    const tamponBoxCartItem = state.cartItems.find((item) => item.productCode === `${ProductCode.TamponBox}` && item.dependantCartItems);
    if (tamponSubscription && tamponBoxCartItem) {
      tamponBoxCartItem.dependantCartItems?.forEach((cartItem) => {
        dispatch({ type: 'cartItemRemove', data: cartItem });
      });
      dispatch({ type: 'cartItemDependantItemsRemove', data: tamponBoxCartItem });
    }
  };

  const updateOrderPrice = () => {
    const hasDiscount = !!state.discountAmount;
    const recurringDiscount = hasDiscount && state.discountExpiryTime;
    const discountAmount = state.discountAmount ?? 0;
    const totalPrice = state.selectedBox?.price || 0;
    let orderPrice: number = totalPrice;
    let subscriptionsPrice: number = totalPrice;

    if (state.cartItems.length > 0) {
      state.cartItems.forEach((item: any) => {
        const itemPrice = item.price * item.quantity;
        orderPrice += itemPrice;
      });
    }

    if (hasDiscount) {
      orderPrice -= discountAmount;
      if (recurringDiscount) subscriptionsPrice -= discountAmount;
    }

    if (state.tax) {
      orderPrice += state.tax;
    }

    dispatch({ type: 'setPrice', data: orderPrice });
    dispatch({ type: 'setRecurringPrice', data: subscriptionsPrice });
  };

  const handleUserSignedIn = async (user: User) => {
    dispatch({ type: 'setUserId', data: user.uid });
    dispatch({ type: 'setLoggedIn', data: true });

    if (user.email) {
      identifyUser(user.email);
    }

    Sentry.configureScope((scope) => {
      scope.setUser({ id: user.uid });
    });
  };

  const clearUserState = () => {
    resetState();
    dispatch({ type: 'setUserId', data: '' });
    dispatch({ type: 'setLoggedIn', data: false });
    // dispatch({ type: 'CLEAR-STATE-AND-STORAGE' });

    // Clear cart here, but only if it was associated with a logged-in user
    if (state.loggedIn) {
      dispatch({ type: 'cartClear' });
    }
  };

  function checkURLParamsForReferralCode() {
    const { search } = location;
    const refCode = new URLSearchParams(search).get('refCode');
    if (refCode) {
      setOnLocalStorage('link-referral-code', refCode);
    }

    const brandbassadorRefCode = new URLSearchParams(search).get('ref');
    if (brandbassadorRefCode) {
      setOnLocalStorage('link-bb-ref', brandbassadorRefCode);
    }
  }

  function getGeolocation() {
    const date = new Date();
    const sessionCountry = getFromStorage('country', false);
    const ecomDataTimestamp = Number(getFromLocalStorage(ecomDataTimestampKey) || '0');
    const refreshTime = 60 * 60 * 1000;
    const shouldRefresh = ecomDataTimestamp + refreshTime < date.getTime();

    if (!shouldRefresh && sessionCountry) {
      console.debug(`Data refresh in: ${Math.round((refreshTime - (date.getTime() - ecomDataTimestamp)) / 60 / 1000)} minutes`);
      return;
    }

    const url = `https://extreme-ip-lookup.com/json/?key=${process.env.EXTREME_KEY}`;
    fetch(url)
      .then((res) => res.json())
      .then((json) => {
        if (!json.countryCode) return;
        const { countryCode } = json;
        dispatch({ type: 'setCountry', data: countryCode });
        getEcommerceData(countryCode);
      })
      .catch((error) => {
        console.warn('extreme-ip-lookup failed: ', error);
      });
  }

  function getEcommerceData(countryCode: string) {
    const oms = getOmsUrl();
    const url = `${oms}/ecommerce/data`;
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json; charset=utf-8'
      }
    };
    fetch(url, options)
      .then((res) => res.json())
      .then((json) => {
        const { shippingFees, promotions, configurations } = json;
        const countryShippingFees =
          shippingFees?.find((item: { countryCode: string }) => item.countryCode === countryCode) ??
          shippingFees.find((item: { countryCode: string }) => item.countryCode === CountryCode.GB);
        if (!promotions || !configurations || !countryShippingFees) return;
        updateEcomData(promotions, configurations, countryShippingFees);
      })
      .catch((err) => {
        throw err;
      });
  }

  function updateEcomData(promotions: PromotionObject[], configurations: ProductConfiguration[], countryShippingFees?: ShippingObject) {
    setOnLocalStorage('promotions', promotions, true);
    setOnLocalStorage('configurations', configurations, true);

    if (countryShippingFees) {
      const currency = {
        letterCode: countryShippingFees.currencyCode,
        symbol: countryShippingFees.currencySymbol
      };
      dispatch({ type: 'setCurrency', data: currency });
      dispatch({ type: 'setShippingFees', data: countryShippingFees.shippingFees });
      dispatch({ type: 'setFreeShippingRequirement', data: countryShippingFees.freeShippingRequirement ?? 50 });

      const date = new Date();
      setOnLocalStorage(ecomDataTimestampKey, date.getTime().toString());
    }
  }

  function setFirebaseAuth() {
    const firebaseApp = initializeApp({
      apiKey: process.env.GOOGLE_API_KEY,
      authDomain: process.env.GOOGLE_AUTH_DOMAIN,
      projectId: process.env.GOOGLE_PROJECT_ID
    });
    const firebaseAuth = initializeAuth(firebaseApp, {
      persistence: [indexedDBLocalPersistence, browserLocalPersistence]
    });
    firebaseAuth.settings.appVerificationDisabledForTesting = process.env.DISABLE_RECAPTCHA === 'true';
    dispatch({ type: 'setFirebaseAuth', data: firebaseAuth });
  }

  return (
    <Context.Provider
      value={{
        state,
        dispatch
      }}
    >
      {children}
    </Context.Provider>
  );
}

export async function getProductPrices() {
  const productPriceTimestamp = getFromLocalStorage('productPriceTimestamp') || 0;
  const timestampNow = new Date().getTime();
  const timeDiff = (timestampNow - Number(productPriceTimestamp)) / 1000;

  if (timeDiff < 60 && getFromLocalStorage('productPrices', true)) {
    return getFromLocalStorage('productPrices', true);
  }

  setOnLocalStorage('productPriceTimestamp', String(timestampNow));

  const oms = getOmsUrl();
  const url = `${oms}/products/prices`;

  const response = await axios.get(url, {
    headers: {
      'Content-Type': 'application/json; charset=utf-8'
    }
  });

  const items = response.data;
  setOnLocalStorage('productPrices', items, true);
  return items;
}

export interface AppState {
  firebaseAuth: Auth | undefined;
  firebaseDb: any;
  loggedIn: boolean | null;
  userId: string;
  firebaseLoaded: boolean;
  selectedPlan: string;
  selectedBox: SelectedBox | undefined;
  cartCount: number;
  cartItems: CartItems;
  price: number;
  tax: number;
  recurringPrice: number;
  recommendedBox: RecommendedBox | undefined;
  statusMessage: string | false;
  discountExpiryTime: number | undefined;
  discountAmount: number | undefined;
  currency: CurrencyObj;
  mfaVerifyId: string | undefined;
  country: CountryCode;
  shippingCost: { shippingPrice: number; originalShippingPrice: number };
  shippingFees: ShippingFees;
  freeShippingRequirement: number;
}

export interface IContext {
  state: AppState;
  dispatch(action: ActionTypes): void;
}

interface SetFirebaseAuth {
  type: 'setFirebaseAuth';
  data: Auth;
}

interface SetCurrency {
  type: 'setCurrency';
  data: CurrencyObj;
}

interface SetCountry {
  type: 'setCountry';
  data: CountryCode;
}

interface SetMfaVerifyId {
  type: 'setMfaVerifyId';
  data: string;
}

interface SetShippingFees {
  type: 'setShippingFees';
  data: ShippingFees;
}

interface SetFirebaseDb {
  type: 'setFirebaseDb';
  data: any;
}

interface SetLoggedIn {
  type: 'setLoggedIn';
  data: boolean;
}

interface SetUserId {
  type: 'setUserId';
  data: string;
}

interface SetFirebaseLoaded {
  type: 'setFirebaseLoaded';
  data: boolean;
}

interface IRehydrateFromSessionStorage {
  type: 'REHYDRATE-FROM-SESSION-STORAGE';
  data: AppState;
}

interface IClearStateSessionStorage {
  type: 'CLEAR-STATE-AND-STORAGE';
}

interface SetSelectedPlan {
  type: 'setSelectedPlan';
  data: string;
}

interface SetSelectedBox {
  type: 'setSelectedBox';
  data: SelectedBox | undefined;
}

interface SetRecommendedBox {
  type: 'setRecommendedBox';
  data: RecommendedBox | undefined;
}

interface CartItemAdd {
  type: 'cartItemAdd';
  data: CartItem;
}

interface CartItemRemove {
  type: 'cartItemRemove';
  data: CartItem;
}

interface CartItemDependantItemsRemove {
  type: 'cartItemDependantItemsRemove';
  data: CartItem;
}

interface CartItemUpdate {
  type: 'cartItemUpdate';
  data: CartItem;
}

interface CartItemQuantityIncrease {
  type: 'cartItemQuantityIncrease';
  data: CartItem;
}

interface CartItemQuantityDecrease {
  type: 'cartItemQuantityDecrease';
  data: CartItem;
}

interface CartClear {
  type: 'cartClear';
}

interface SetPrice {
  type: 'setPrice';
  data: number;
}

interface SetTax {
  type: 'setTax';
  data: number;
}

interface SetRecurringPrice {
  type: 'setRecurringPrice';
  data: number;
}

interface UpdateShippingCost {
  type: 'updateShippingCost';
  data: string | undefined;
}

interface SetFreeShippingRequirement {
  type: 'setFreeShippingRequirement';
  data: number;
}

interface SetStatusMessage {
  type: 'setStatusMessage';
  data: string | false;
}

interface SetDiscountExpiryTime {
  type: 'setDiscountExpiryTime';
  data: number | undefined;
}

interface SetDiscountAmount {
  type: 'setDiscountAmount';
  data: number;
}

type ActionTypes =
  | SetFirebaseAuth
  | SetFirebaseDb
  | SetLoggedIn
  | SetUserId
  | SetFirebaseLoaded
  | IRehydrateFromSessionStorage
  | IClearStateSessionStorage
  | SetSelectedPlan
  | SetSelectedBox
  | SetRecommendedBox
  | CartItemAdd
  | CartItemRemove
  | CartItemDependantItemsRemove
  | CartItemUpdate
  | CartItemQuantityIncrease
  | CartItemQuantityDecrease
  | CartClear
  | SetPrice
  | SetTax
  | SetRecurringPrice
  | SetStatusMessage
  | SetDiscountExpiryTime
  | SetDiscountAmount
  | SetMfaVerifyId
  | SetCountry
  | SetCurrency
  | UpdateShippingCost
  | SetShippingFees
  | SetFreeShippingRequirement;

export { Context, Provider };
